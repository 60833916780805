<template>
  <div class="title-panel">
    <h2>Log-Eintrag einsehen</h2>
  </div>

  <LogEntriesCreateEdit :id="id" :dto="dto"></LogEntriesCreateEdit>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { LogEntryDto } from "@/dtos/LogEntryDtos";
import { FormHelper } from "@/helpers/FormHelper";
import routerMixins from "@/mixins/routerMixins";
import LogEntriesCreateEdit from "@/components/controlpanel/logEntries/LogEntriesCreateEdit.vue";
import { LogEntryModel } from "@/models/LogEntryModel";
import { useLogEntryStore } from "@/stores/logEntryStore";

/**
 * View that renders the the "edit" form for logEntries.
 */
export default defineComponent({
  name: "LogEntriesEdit",
  setup() {
    const store = useLogEntryStore();
    const route = useRoute();

    const formHelper = new FormHelper("/controlpanel/logentries");

    // validate parameter.
    const id = routerMixins.tryGetParam(route.params.id, () => {
      formHelper.returnToList();
    });

    const dto = ref(new LogEntryDto(new LogEntryModel()));

    // get entity from store.
    onMounted(() => {
      store.getLogEntry(dto.value, id);
    });

    return {
      id,
      dto,
    };
  },
  components: { LogEntriesCreateEdit },
});
</script>

<style scoped lang="scss"></style>
